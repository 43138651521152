<template>
  <FinalField :name="name" ref="field" :validate="validate">
    <template v-slot="props">
      <div class="form-col">
        <div class="mb-2 text-xs font-bold flex justify-between">
          {{label}}
          <p v-if="!addNew" @click="addNew = true" class="text-blue-500 pl-4 cursor-pointer flex items-center">
            <Icon name="plus" class="2-3 h-3 mr-1"/>
            add New
          </p>
          <p v-if="addNew" @click="addNew = false" class="text-blue-500 pl-4 cursor-pointer flex items-center">
            cancel Add
          </p>
        </div>
        <Dropdown
          v-if="!addNew"
          :options="options"
          :value="props.value"
          @input="val => props.change(val)"
          :placeholder="placeholder"
        />
        <div v-if="addNew">
          <text-input
            :placeholder="`${label}...`"
            v-on="{...props.events, input: val => props.change(val)}"
            :value="props.value"
          />
        </div>
        <span class="form-hint form-error" v-if="props.meta.error && props.meta.touched">
          {{ props.meta.error }}
        </span>
      </div>
    </template>
  </FinalField>
</template>

<script>
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import Dropdown from "@/components/ui/Dropdown";
  import Icon from "@/components/ui/Icon";
  import {FinalField} from 'vue-final-form';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import TextInput from '@/components/ui/TextInput';

  export default {
    name: "AddOrSelectInput",
    components: {
      Icon,
      Dropdown,
      FinalField,
      TextInput,
    },
    mixins: [
      ValidatorMixin,
      NotifyMixin
    ],
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      placeholder: {
        type: String,
        default: 'Select item'
      },
      options: {
        type: Array,
        default: () => []
      },
      validate: {
        type: [Array, Function]
      }
    },
    data() {
      return {
        addNew: false
      }
    },
    methods: {
      toggleAddNew(val) {
        this.addNew = val;
      }
    },
    watch: {
      addNew: function () {
        const {change, value} = this.$refs.field.fieldState;
        if (value) {
          change(undefined);
        }
      }
    },
  }
</script>
