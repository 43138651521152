<template>
  <div>
    <FinalForm
      :submit="addPurpose"
      ref="form"
    >
      <template v-slot="props">
        <form @submit="props.handleSubmit">
          <div class="bg-gray-100 p-4 mb-6">
            <div class="form-row">
              <AddOrSelectInput
                ref="purposes"
                name="purpose"
                label="purpose"
                placeholder="select purpose"
                :options="purposeOptions"
                :validate="composeValidators(required, uniqPurposeName)"
              />
              <AddOrSelectInput
                ref="categories"
                name="category"
                label="category"
                placeholder="select category"
                :options="categoryOptions"
                :validate="composeValidators(required, uniqCategoryName)"
              />
              <div class="w-1/12 flex items-center justify-center">
                <button type="submit" :disabled="loading">
                  <icon
                    name="plus"
                    class="h-5 w-5 text-blue-500 cursor-pointer"
                  />
                </button>
              </div>
            </div>
            <div v-if="showDescription" class="form-row">
              <TextField name="description" :validate="required" label="description"/>
            </div>
          </div>
        </form>
      </template>
    </FinalForm>
    <div class="purpose" v-for="purpose in parentPurposes" :key="purpose.uuid">
      <div class="flex flex-row">
        <div class="form-col font-bold">{{ purpose.name }}</div>
        <div class="form-col">{{ purpose.category && purpose.category.name }}</div>
        <button type="button" @click="removePurpose(purpose.uuid)">
          <icon
            name="closeCircle"
            class="h-5 w-5 text-gray-500 mx-auto cursor-pointer"
          />
        </button>
      </div>
      <div class="text-sm mt-2">{{ purpose.description }}</div>
    </div>
    <Loader :backdrop="true" :loading="loading"/>
    <ModalFooter :footer="footer" :primary="goBack"/>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import {FinalForm} from 'vue-final-form';
import ValidatorMixin from "@/components/form/ValidatorMixin";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import NotifyMixin from "@/mixins/NotifyMixin";
import Icon from "@/components/ui/Icon";
import AddOrSelectInput from "@/components/auth/form/AddOrSelectInput";
import TextField from "@/components/form/TextField";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";

export default {
  name: "IntegrationPurposes",
  components: {
    ModalFooter,
    Loader,
    TextField,
    AddOrSelectInput,
    Icon,
    FinalForm,
  },
  mixins: [
    ModalNavigation,
    ValidatorMixin,
    InitializeFormMixin,
    NotifyMixin
  ],
  data() {
    return {
      purposes: [],
      categories: [],
      showDescription: false,
      parentPurposes: [],
      loading: false,
      footer: {
        primaryButton: 'go back'
      }
    }
  },
  computed: {
    categoryOptions() {
      return this.categories.map(({name: value, uuid: key}) => ({key, value}))
    },
    purposeOptions() {
      const categoryId = this.$refs.form?.formState?.values?.category;

      return this.purposes
        .filter(
          ({uuid, category}) => {
            return !this.parentPurposes.flatMap(({uuid}) => uuid).includes(uuid)
              && (!categoryId || category.uuid === categoryId);
          }
        )
        .map(({name: value, uuid: key}) => ({
          key,
          value
        }))
    },
    partnerId() {
      return this.$route.params.partnerId;
    },
    purposeNames() {
      return this.purposes.map(p => p.name);
    },
    categoryNames() {
      return this.categories.map(c => c.name);
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'integrations.edit',
        params: {direction: 'back', ...this.$route.params}
      });
    },
    async addPurpose({purpose, category, description}) {
      this.loading = true;
      let purposeObj;
      let categoryObj;

      try {
        if (this.$refs.categories.addNew) {
          categoryObj = await this.$partnerDataProvider.create('purposeCategories', {data: {name: category}})
          this.categories.push(categoryObj);
        } else {
          categoryObj = this.categories.find(c => c.uuid === category);
        }

        if (this.$refs.purposes.addNew) {
          purposeObj = await this.$partnerDataProvider.create('purposes', {
            data: {
              name: purpose,
              categoryUuid: categoryObj.uuid,
              description,
              active: true
            }
          })
        } else {
          purposeObj = this.purposes.find(p => p.uuid === purpose);
        }

        const partnerPurpose = await this.$partnerDataProvider.create('partnersPurposes', {
          partnerId: this.partnerId,
          data: {purposeId: purposeObj.uuid}
        });

        this.parentPurposes.push(partnerPurpose);
        this.$refs.purposes.toggleAddNew(false);
        this.$refs.categories.toggleAddNew(false);

        setTimeout(this.$refs.form.finalForm.reset);
        this.$refs.form.finalForm.resetFieldState('category');
      } catch (e) {
        this.notifyError(e.message);
      }

      this.loading = false;
    },
    removePurpose(id) {
      this.loading = true;
      this.$partnerDataProvider.delete('partnersPurposes', {partnerId: this.partnerId, id})
        .then(() => this.parentPurposes = this.parentPurposes.filter(p => p.uuid !== id))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.loading = false);
    },
    getPurposes() {
      return this.$partnerDataProvider.getList('purposes')
        .then(content => {
          this.purposes = content;
        })
        .catch((error) => this.notifyError(error.message));
    },
    getPurposeCategories() {
      return this.$partnerDataProvider.getList('purposeCategories')
        .then(({content}) => {
          this.categories = content;
        })
        .catch((error) => this.notifyError(error.message));
    },
    getPartnersPurposes() {
      return this.$partnerDataProvider.getList('partnersPurposes', {partnerId: this.partnerId})
        .then(({content}) => this.parentPurposes = content)
        .catch((error) => this.notifyError(error.message));
    },
    uniqPurposeName(val) {
      return val && this.purposeNames.includes(val)
        ? `purpose ${val} already exists`
        : undefined;
    },
    uniqCategoryName(val) {
      return val && this.categoryNames.includes(val)
        ? `category ${val} already exists`
        : undefined;
    }
  },
  mounted() {
    this.$watch(
      '$refs.purposes.addNew',
      val => {
        this.showDescription = val;
      }
    );

    this.$watch(
      '$refs.form.formState.values.purpose',
      val => {
        if (val && !this.$refs.purposes.addNew && !this.$refs.categories.addNew) {
          const purpose = this.purposes.find(({uuid}) => uuid === val);
          this.$refs.form.finalForm.change('category', purpose.category.uuid);
        }
      }
    );

    this.$watch(
      '$refs.form.formState.values.category',
      val => {
        const purposeId = this.$refs.form?.formState?.values?.purpose;
        const purpose = this.purposes.find(({uuid}) => uuid === purposeId);

        if (!this.$refs.purposes.addNew && !this.$refs.categories.addNew && purpose?.category.uuid !== val) {
          this.$refs.form.finalForm.resetFieldState('purpose');
        }
      }
    );

    this.loading = true;

    Promise.all([
      this.getPurposes(),
      this.getPurposeCategories(),
      this.getPartnersPurposes(),
    ])
      .finally(() => this.loading = false);

  }
}
</script>

<style scoped>
.purpose {
  @apply p-4 mb-0;
}

.purpose:hover {
  @apply bg-blue-50;
}
</style>
